import * as React from "react";
import { Show, SimpleShowLayout, TextField, UrlField, FunctionField, useRecordContext, Link } from 'react-admin';
const AdminUserIDFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const AdminUserIDUpdateFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const UserBuyCouponPaymentInfoDetail = ({ ...props }) => (

    <Show title="User coupon buy payment info" {...props}>
        <SimpleShowLayout>
            <TextField source="profile_id" label="Profile Id" />
            <TextField source='name' label="name" />
            <TextField source='mobile' label="mobile" />
            <TextField source='email' label="email" />
            <TextField source='paid_date' label="paid_date" />
            <TextField source="team" label="team" />
            <TextField source="paid_method" label="paid_method" />
            <TextField source="paid_amount" label="paid_amount" />
            <FunctionField source="file_proof" label="Payment Proof" render={record => {
                console.log('dfdfdf',record)
                if (record['payment_proofurl']) {
                    return (<img style={{ maxWidth: '100px' }} src={record['payment_proofurl']} alt={record.name} />)
                }
            }} />

            <TextField source='referance_number' label="referance_number" />
            <TextField source="decline_reason" label="Decline Reason" />
            <FunctionField source="status" label="Status" render={record => {
                if (record['status'] === 'Reject') {
                    return (<span style={{ color: 'red' }}>Reject</span>)
                } else if (record['status'] === 'Pending') {
                    return (<span style={{ color: 'orange' }}>Pending</span>)
                } else {
                    return (<span style={{ color: 'green' }}>Approved</span>)
                }
            }} />
            <TextField source="approvedby.name" label="Approved By" />
            <TextField source="rejactedby.name" label="Rejacted By" />
            <TextField source="approved_at" label="Approved Date" />
            <TextField source="rejected_at" label="Rejected Date" />
        </SimpleShowLayout>
    </Show>
);
export default UserBuyCouponPaymentInfoDetail