import { LanguageVideoUpload } from "./LanguageVideo.Upload"
import { UserUpload } from "./User.Upload"
import { BulckBonusUpload } from "./BulckBonus.Upload"
import {BulckUserActiveUpload}from "./BulckUserActive.Upload" 
import {AddBulckUserWelcomeBonusUpload}from "./AddBulckUserWelcomeBonus.Upload" 
import {WithdrawPaymentInfoUpload}from "./WithdrawPaymentInfo.Upload"
import{ArticleUpload}from "./Article.Upload" 
import { SiteCarouselSlideUpload } from "./SiteCarouselSlide.Upload"
import{ShopUpload}from "./Shop.Upload" 
import{BulckUserActiveWithIntroUpload}from "./BulckUserActiveWithIntro.Upload" 
import{BulckUserCouponPaymentActiveUpload}from "./BulckUserCouponPaymentActive.Upload" 


const Uploader = (resource, formdata) => {
    switch (resource) {       
        case 'languagevideo': return LanguageVideoUpload(formdata);   
        case 'shop': return ShopUpload(formdata);   
        case 'user': return UserUpload(formdata);    
        case 'sitecarouselslide': return SiteCarouselSlideUpload(formdata);
        case 'adminbulckbonusadd': return BulckBonusUpload(formdata); 
        case 'adminbulckuseractive': return BulckUserActiveUpload(formdata); 
        case 'adminbulckuseraddwelcomebonus': return AddBulckUserWelcomeBonusUpload(formdata); 
        case 'adminbulckuseraddwelcomebonus': return AddBulckUserWelcomeBonusUpload(formdata); 
        case 'article':return ArticleUpload(formdata); 
        case 'adminbulckuseractivewithintro': return BulckUserActiveWithIntroUpload(formdata); 
       case 'withdrawpaymentinfo':return WithdrawPaymentInfoUpload(formdata); 
       case 'adminbulckusercouponpaymentactive':return BulckUserCouponPaymentActiveUpload(formdata); 
        default: return formdata;
    }
}

export default Uploader