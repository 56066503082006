import React, { Fragment } from 'react'
import {
    List, Datagrid, Filter, ShowButton, useListContext, Button, TextInput, TopToolbar,TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton, useRecordContext, Link
} from 'react-admin'
import { InfluencerStatusSelectInput ,UserTeamSelectInput,UserPaidSelectInput} from '../../UI/Input/SelectInput'

import { stringify } from 'query-string';
import DownloadIcon from '@material-ui/icons/GetApp';
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <InfluencerStatusSelectInput label="Status" source="status" alwaysOn />
        <UserTeamSelectInput label="Team type" source="teamtype" alwaysOn />
        <UserPaidSelectInput label="Paid Method" source="paidmethod" alwaysOn />
      
    </Filter>
)

const ListActions  = (props) => {
    const record = useListContext();
    const filva = record.filterValues ? stringify(record.filterValues) : ''; 
    return ( <TopToolbar>
      <Button href={`//sboportal.org.in/admin/api/v1/usercouponbuypaymentinfo/export?${filva}`} label="Buy Coupon PAyment Info Export" ><DownloadIcon /></Button>
  </TopToolbar>)
}
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}
const ProfileidFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}


const UserBuyCouponPaymentInfoList = ({ ...props }) => {

    return (
        <List {...props} actions={<ListActions />} title="User coupon buy payment info" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'title', order: 'ASC' }}>

            <Datagrid >

                <ProfileidFiled source="profile_id" label="Profile Id" />
                <TextField source='name' label="name" />
                <TextField source='mobile' label="mobile" />
                <TextField source='email' label="email" />
                <TextField source='paid_date' label="paid_date" />
                <TextField source="team" label="team" />
                <TextField source="paid_method" label="paid_method" />
                <TextField source="paid_amount" label="paid_amount" />
                <FunctionField source="file_proof" label="Payment Proof" render={record => {
                    if (record['payment_proofurl']) {
                        return (<img style={{ maxWidth: '100px' }} src={record['payment_proofurl']} alt={record.name} />)
                    }
                }} />
                <FunctionField source="status" label="Status" render={record => {
                    if (record['status'] === 'Reject') {
                        return (<span style={{ color: 'red' }}>Reject</span>)
                    } else if (record['status'] === 'Pending') {
                        return (<span style={{ color: 'orange' }}>Pending</span>)
                    } else {
                        return (<span style={{ color: 'green' }}>Approved</span>)
                    }
                }} />
                <TextField source='referance_number' label="referance_number" />
                {useCheckPermissions('Usercouponbuypaymentinfo_Edit') ? <FunctionField source="status" label="Edit Action" render={record => {
                    if (record['status'] === 'Pending') {
                        return (<EditButton />)
                    }

                }} /> : null}
                {useCheckPermissions('Usercouponbuypaymentinfo_Show') ? <ShowButton /> : null}

            </Datagrid>

        </List>
    )
}


export default UserBuyCouponPaymentInfoList