import * as React from "react";
import { Show, SimpleShowLayout, TextField,FunctionField,useRecordContext, Link } from 'react-admin';
const AdminUserIDFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const AdminUserIDUpdateFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/adminuser?filter={"q":"${record.id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const ContentWriterDetail = ({ ...props }) => (

    <Show title="Content writer keyword detail" {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="content_url" label="Content URL"/>
            <TextField source="title" label="Title"/>
            <TextField source="description" label="Description"/>
            <TextField source="keywords" label="Keywords"/>
            <TextField source="keyword_count" label="Keyword Count"/>
            <TextField source="amount" label="Amount"/>
            <TextField source="status" label="Status"/>
            <TextField source="submit_date" label="Submit Date"/>
            <TextField source="reason" label="Reason" />
            <TextField source="deduction_amount" label="Deduction Amount" />
            <TextField source="wallet_type" label="Wallet Type" />
            <TextField source="before_wallet_amount" label="Before Wallet Amount" />
            <TextField source="action_at" label="Action At" />
            <TextField source="actionBy.name" label="Action By" />
        </SimpleShowLayout>
    </Show>
);
export default ContentWriterDetail