import React, { useState, useMemo, Fragment } from 'react'
import {
    List, Datagrid, Filter, TopToolbar, DateInput, Button, useListContext, useRefresh, SelectInput, required, FormDataConsumer, useNotify, TextInput, TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton, useRecordContext, Link
} from 'react-admin'
import { stringify } from 'query-string';
import DownloadIcon from '@material-ui/icons/GetApp';
import { InfluencerStatusSelectInput } from '../../UI/Input/SelectInput'
import BulkActiveButton from '../Microtask/Action/Bulk.Active.Button';

const ListActions  = (props) => {
      const record = useListContext();
      const filva = record.filterValues ? stringify(record.filterValues) : ''; 
      return ( <TopToolbar>
        <Button href={`//sboportal.org.in/admin/api/v1/usercouponbuy/export?${filva}`} label="Buy Coupon Export" ><DownloadIcon /></Button>
    </TopToolbar>)
}
const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
         <InfluencerStatusSelectInput label="Status" source="status" alwaysOn/>
<DateInput source="current_date" lable="Date" alwaysOn/>
    </Filter>
)


const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}
const ProfileidFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}

const BulkActionButtons = ({ gift_id, ...props }) => {

    return (<Fragment>
        <BulkActiveButton label="Bulck Approve"  {...props} />
    </Fragment>)
}


const UserCouponBuyList = ({ ...props }) => {

    return (
        <List {...props} actions={<ListActions />} title="User Buy Coupon" bulkActionButtons={<BulkActionButtons />} filters={<ListFilter />} sort={{ field: 'title', order: 'ASC' }}>

            <Datagrid >
                <TextField source="id" />
                <ProfileidFiled source="profile_id" label="Profile Id" />
                <TextField source='name' label="Name" />
                <TextField source="mobile" label="Mobile" />
                <TextField source="email" label="Email" />
                <FunctionField source="status" label="Status" render={record => {

                    if (record['status'] === 'Rejected') {
                        return (<span style={{ color: 'red' }}>Reject</span>)
                    } else if (record['status'] === 'Pending') {
                        return (<span style={{ color: 'orange' }} >Pending</span>)
                    }
                    else {
                        return (<span style={{ color: 'green' }}>Approved</span>)
                    }
                }} />
                <TextField source="intrested" label="Intrested" />
                <TextField source='created_at' label="Date" />
            </Datagrid>
        </List>
    )

}


export default UserCouponBuyList 