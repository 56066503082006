import { stringify } from 'query-string'
import { fetchUtils, HttpError } from 'react-admin'
import jsonServerProvider from 'ra-data-json-server'
import Uploader from './Upload';

export const server = {
    //url: ' http://127.0.0.1:8000/admin/api/v1'
    // url: 'https://sbo.sbowork.org.in/admin/api/v1'
    url: 'https://sboportal.org.in/admin/api/v1'
};
export const apiCallback = {
    success: null,
    fail: null,
    error: null,
};

export const AUTH_USER_KEY = "rauth_admin";

export const apiHeader = {
    'Accept': 'application/json',
    'pragma': 'no-cache',
    'cache-control': 'no-cache',
    'Sec-Fetch-Mode': 'cors',
    'Sec-Fetch-Site': 'cross-site',
};


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    options.headers.set('pragma', 'no-cache');
    options.headers.set('cache-control', 'no-cache');

    var user = JSON.parse(localStorage.getItem(AUTH_USER_KEY));
    if (user) {
        options.headers.set('Authorization', 'Bearer ' + user.token);
    }

    return fetchUtils.fetchJson(url, options).then((data) => {

        if (data.json) {
            if (!data.json.status) {
                throw new HttpError(data.json.message ? data.json.message : 'Unable to process request', data.status, data.json.errors ? data.json.errors : {})
            }
            data.json = data.json.data;
        }
        return data;
    })
}

export const dataProvider = {
    ...jsonServerProvider(server.url, httpClient),
    update: (resource, params) => {

        var postdata = Uploader(resource, params.data);
        var isupload = postdata instanceof FormData;
        if (isupload) {
            postdata.append('_method', 'PUT');
        }

        return httpClient(`${server.url}/${resource}/${params.id}`, {
            method: isupload ? 'POST' : 'PUT',
            body: isupload ? postdata : JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
    },
    create: (resource, params) => {
        var postdata = Uploader(resource, params.data);
        var isupload = postdata instanceof FormData;

        return httpClient(`${server.url}/${resource}`, {
            method: 'POST',
            body: isupload ? postdata : JSON.stringify(params.data),
        }).then(({ json }) => ({ data: { ...params.data, id: json.id }, }))
    }
};


export const fetchApi = async (path, data, method, callback, header) => {

    callback = { ...apiCallback, ...callback };
    header = { ...apiHeader, ...header };

    var user = JSON.parse(localStorage.getItem(AUTH_USER_KEY));
    if (user) {
        header = { ...header, ...{ Authorization: 'Bearer ' + user.token } };
    }
    if (!(data instanceof FormData)) {
        header = { ...header, ...{ 'Content-Type': 'application/x-www-form-urlencoded' } };
    }

    try {
        return fetch(server.url + '/' + path, {
            method: method,
            headers: header,
            //redirect: 'follow',
            //mode: 'no-cors',
            body: data ? ((data instanceof FormData) ? data : stringify(data)) : null
        }).then((response) => {
            try {
                return response.json();
            } catch (e) {
                //console.log('response parse', e);
            }
            return {};
        }).then((responseJson) => {
            try {
                if (!responseJson.status) {
                    if (callback.fail) callback.fail(responseJson);
                } else {
                    if (callback.success) callback.success(responseJson);
                }
            } catch (e) {
                // console.log("Application error", e);
                callback.error("Application error");
            }
            return responseJson;
        }).catch((error) => {
            if (callback.error) callback.error(error.me);
        });
    } catch (e) {
        callback.error(e.message);
    }
}


export const fetchGet = (path, data, callback, header) => {
    return fetchApi(path, data, 'GET', callback, header);
}
export const fetchPost = (path, data, callback, header) => {
    return fetchApi(path, data, 'POST', callback, header);
}
export const fetchPut = (path, data, callback, header) => {
    return fetchApi(path, data, 'PUT', callback, header);
}
export const fetchDelete = (path, data, callback, header) => {
    return fetchApi(path, data, 'DELETE', callback, header);
}