import { Menu, usePermissions } from 'react-admin';
import LabelIcon from '@mui/icons-material/Label';
import CustomMenu from './CustomMenu';
import useCheckPermissions from '../AdminPermissions/useCheckPermissions'

export const MyMenu = () => {
    const { permissions } = usePermissions();
    return (<Menu>
        {permissions && permissions.role!='' && permissions.role == "SuperAdmin" ? <Menu.ResourceItem name="sbomodules" /> : null}
        {useCheckPermissions('Dashboard_View') ? <Menu.DashboardItem /> : null}
        {useCheckPermissions('AdminUser_View') ? <Menu.ResourceItem name="adminuser" /> : null}
        {useCheckPermissions('Shop_View') ? <Menu.ResourceItem name="shop" /> : null}
        {useCheckPermissions('ShopCategory_View') ? <Menu.ResourceItem name="shopcategory" /> : null}
        {useCheckPermissions('DigitalTaskApplication_View') ? <Menu.ResourceItem name="digitaltaskapplication" /> : null}
        {useCheckPermissions('Influencer_View') ? <Menu.ResourceItem name="influencer" /> : null}
        {useCheckPermissions('SiteCarousel_View') ? <Menu.ResourceItem name="sitecarousel" /> : null}
        {useCheckPermissions('InfluencerAll_View') ? <Menu.ResourceItem name="influencerall" primaryText="AllTask" leftIcon={<LabelIcon />} /> : null}
        {useCheckPermissions('Microtask_View') ? <Menu.ResourceItem name="microtask" /> : null}
        {useCheckPermissions('Usercouponbuy_View') ? <Menu.ResourceItem name="usercouponbuy" /> : null}
        {useCheckPermissions('Usercouponbuypaymentinfo_View') ? <Menu.ResourceItem name="usercouponpaymentinfobuy" /> : null}
        {useCheckPermissions('Usersearch_View') ? <Menu.ResourceItem name="usersearch" /> : null}
        {useCheckPermissions('InfluencerRating_View') ? <Menu.ResourceItem name="influencerrating" /> : null}
        {useCheckPermissions('ShopBank_View') ? <Menu.ResourceItem name="shopbank" /> : null}
        {useCheckPermissions('PendingUser_View') ? <Menu.ResourceItem name="pendinguser" /> : null}
        {useCheckPermissions('UserCommands') ? <Menu.ResourceItem name="usercommands" /> : null}
        {useCheckPermissions('User_View') ? <Menu.ResourceItem name="user" /> : null}
        {useCheckPermissions('ContentWriterKeywords_View') ? <Menu.ResourceItem name="contentwriterkeywords" /> : null}
        {useCheckPermissions('ContentWriterKeywordAmount_View') ? <Menu.ResourceItem name="contentwriterkeywordamount" /> : null}
        {useCheckPermissions('ContentWriter_View') ? <Menu.ResourceItem name="contentwriter" /> : null}
        {useCheckPermissions('MerchandiserApply_View') ? <Menu.ResourceItem name="merchandiserapply" /> : null}
        {useCheckPermissions('DeepavaliBonus_View') ? <Menu.ResourceItem name="deepavalibonus" /> : null}
        {useCheckPermissions('AdminBulckUserActiveWithIntro_Create') ? <Menu.Item to="/adminbulckuseractivewithintro/create" primaryText="New Joiner Bulck Active" leftIcon={<LabelIcon />} /> : null}
        {useCheckPermissions('WithdrawRequest_View') ? <Menu.ResourceItem name="withdrawrequest" /> : null}
        {useCheckPermissions('PlanUpgradeRequest_View') ? <Menu.ResourceItem name="planupgraderequest" /> : null}
        {useCheckPermissions('LanguageVideo_View') ? <Menu.ResourceItem name="languagevideo" /> : null}
        {useCheckPermissions('VideoList_View') ? <Menu.ResourceItem name="videolist" /> : null}
        {useCheckPermissions('CategoryList_View') ? <Menu.ResourceItem name="categorylist" /> : null}
        {useCheckPermissions('Code_View') ? <Menu.ResourceItem name="code" /> : null}
        {useCheckPermissions('UserUtr_View') ? <Menu.ResourceItem name="userutr" /> : null}
        {useCheckPermissions('BasicPlan_View') ? <Menu.ResourceItem name="basicplan" /> : null}
        {useCheckPermissions('UpgradePlan_View') ? <Menu.ResourceItem name="upgradeplan" /> : null}
        {useCheckPermissions('IntroWithdrawRequest_View') ? <Menu.ResourceItem name="introwithdrawrequest" /> : null}
        {useCheckPermissions('BuybackWithdrawRequest_View') ? <Menu.ResourceItem name="buybackwithdrawrequest" /> : null}
        {useCheckPermissions('WelcomeBonusWithdrawRequest_View') ? <Menu.ResourceItem name="welcomebonuswithdrawrequest" /> : null}
        {useCheckPermissions('BasicSettings_View') ? <Menu.ResourceItem name="basicsettings" /> : null}
        {useCheckPermissions('Article_View') ? <Menu.ResourceItem name="article" /> : null}
        {useCheckPermissions('SboIncomeTaxApplicant_View') ? <Menu.ResourceItem name="sboincometaxapplicant" /> : null}
        {useCheckPermissions('RefundDocument_View') ? <Menu.ResourceItem name="refunddocument" /> : null}
        {useCheckPermissions('RefundPaymentProof_View') ? <Menu.ResourceItem name="refundpaymentproof" /> : null}
        {useCheckPermissions('OnlineCourse_View') ? <Menu.ResourceItem name="onlinecourse" /> : null}
        {useCheckPermissions('AgreementAndPayment_View') ? <Menu.ResourceItem name="agreementandpayment" /> : null}
        {useCheckPermissions('SboCare_View') ? <Menu.ResourceItem name="sbocare" /> : null}
        {useCheckPermissions('AdminBonusAdd_Create') ? <Menu.Item to="/adminbonusadd/create" primaryText="Add Bonus" leftIcon={<LabelIcon />} /> : null}
        {useCheckPermissions('AdminBulckUserAddWelcomeBonus_Create') ? <Menu.Item to="/adminbulckuseraddwelcomebonus/create" primaryText="Add Bulck User Welcome Bonus" leftIcon={<LabelIcon />} /> : null}
        {useCheckPermissions('AdminBulckUserActive_Create') ? <Menu.Item to="/adminbulckuseractive/create" primaryText="Bulck User Active" leftIcon={<LabelIcon />} /> : null}

        {useCheckPermissions('Usercouponbuypaymentinfo_Create') ? <Menu.Item to="/adminbulckusercouponpaymentactive/create" primaryText="Bulck User coupon buy payment info Active" leftIcon={<LabelIcon />} /> : null}

        {useCheckPermissions('WithdrawPaymentInfo_Create') ? <Menu.Item to="/withdrawpaymentinfo/create" primaryText="Withdraw Payment Info" leftIcon={<LabelIcon />} /> : null}
        {useCheckPermissions('AdminBulckBonusAdd_Create') ? <Menu.Item to="/adminbulckbonusadd/create" primaryText="Add Bulk Bonus" leftIcon={<LabelIcon />} /> : null}

        {useCheckPermissions('AdminSingleUserAddOrDeduct_Create') ? <Menu.Item to="/adminsingleuseraddordeduct/create" primaryText="Single User Add or Deduct" leftIcon={<LabelIcon />} /> : null}

        {useCheckPermissions('AdminBulckUserAddWorkingAmount_Create') ? <Menu.Item to="/adminbulckuseraddworkingamount/create" primaryText="Bulck Add Working Non-working" leftIcon={<LabelIcon />} /> : null}

        {useCheckPermissions('PublicCloudStorageList_View') ? <Menu.ResourceItem name="publiccloudstoragelist" /> : null}

    </Menu>
    )

}