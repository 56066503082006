import React, { Fragment, useState, useMemo } from 'react'
import {
    List, Datagrid, Filter, ShowButton, Button, Toolbar, SaveButton, SimpleForm, useRefresh, SelectInput, required, FormDataConsumer, useNotify, useListContext, TextInput, TextField, EditButton, BulkDeleteButton, FunctionField, DeleteButton, useRecordContext, Link
} from 'react-admin'
import { ContentWriterStatusSelectInput,AdminUserSelectInput } from '../../UI/Input/SelectInput'
import DownloadIcon from '@material-ui/icons/GetApp';
import { DateRangeInput } from '../../UI/Input/input';
import useCheckPermissions from '../AdminPermissions/useCheckPermissions';
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@material-ui/core/Grid";
import { actionOfMicroAndContentWritter } from '../../Model/actionOfMicroAndContentWritter';

const ListFilter = (props) => {
    const {

        setFilters,
        hideFilter
    } = useListContext();

    return (<Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <DateRangeInput alwaysOn onClear={() => { hideFilter('daterange') }} onChange={(strdate) => {
            setFilters({ daterange: strdate })
        }} />
        <TextInput label="No of url" source="no_of_url" alwaysOn />
        <AdminUserSelectInput label="Admin User" source="adminuser" alwaysOn />
        <ContentWriterStatusSelectInput label="Status" source="status" alwaysOn/>

    </Filter>
    )
}
const ListActionButtons = ({ ...props }) => {
    return (<>
        <BulkDeleteButton {...props} undoable={false}
            confirmTitle="Delete File Record"
            confirmContent={"Are you sure you want to delete this record?"} />
    </>)
}

const ExportHtml = (props) => {
    const record = useRecordContext();
    var itemid = (record && record.id > 0) ? record.id : 0;
    return <Button href={`//sboportal.org.in/admin/api/v1/exporthtml?itemid=${itemid}`} size="small"><DownloadIcon /></Button>
}
const ProfileidFiled = (props) => {
    const record = useRecordContext();
    return (
        <TextField {...props} component={Link} to={`/user?filter={"q":"${record.profile_id}"}`} onClick={(event) => event.stopPropagation()} />
    )
}
const ContentUrl = (props) => {
    const record = useRecordContext();
    return (

        <a label="URL" target='_blank' href={record.content_url} size="small">
            <Button style={{ color: 'purple', border: '1px solid' }} label="URL" size="small"></Button>
        </a>
    )
}
const PreviewImgeUploaded = (props) => {
    const record = useRecordContext();
    return (

        <a target='_blank' href={record.image_url} size="small">
            <img src={record.image_url} style={{ width: '50px', height: '50px' }} alt='previewimage' />
        </a>
    )
}

const SanitizedGrid = ({ basePath, ...props }) => {
    return <Grid {...props} />;
};
const FormToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="Submit" redirect={null} submitonenter={false} />
        {!props.saving && <Button label="ra.action.cancel" onClick={props.onCancel}>
            <IconCancel />
        </Button>}
    </Toolbar>
)



const ContentWriterList = ({ ...props }) => {


    const [open, setOpen] = useState(false);
    const refresh = useRefresh()
    const notify = useNotify()

    const [inputValue, setInputValue] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [recordStatus, setRecordStatus] = useState(null);
    const handleOpenDialog = (record) => {

        setSelectedId(record.id);
        setRecordStatus(record.status);
        setOpen(true);
    }
    const handleCloseDialog = () => setOpen(false);


    // Function to handle submit action in the dialog
    const handleSubmit = (record) => {

        const data = {
            "rid": selectedId,
            "type": 'contentwritter',
            "status": record.status,
            "reason": record.decline_reason
        }
        actionOfMicroAndContentWritter(data, selectedId, (res) => {
            refresh()
            notify(res.message)
        }, (res) => {
            notify(res.message, 'warning')
        })
        setOpen(false);

    };

    const choices = useMemo(() => {

        if (recordStatus === 'Pending') {
            return [
                { id: 'Approved', name: 'Approved' },
                { id: 'Reject', name: 'Reject' },
            ]
        } else if (recordStatus === 'Approved') {
            return [
                { id: 'Pending', name: 'Pending' },
                { id: 'Reject', name: 'Reject' },
            ]
        } else {
            return [
                { id: 'Pending', name: 'Pending' },
                { id: 'Approved', name: 'Approved' },
            ]
        }
    }, [recordStatus]);

    const OpenDialogButton = ({ onClick }) => {
        const record = useRecordContext(); // Access the current record context
        if (!record) return null; // Ensure record exists
        return (

            <Button style={{ color: 'purple', border: '1px solid' }} onClick={() => onClick(record)} label="Update Action" size="small"></Button>
        );
    };


    return (
        <Fragment>
            <List {...props} title="Content Writer Keywords" bulkActionButtons={<ListActionButtons />} filters={<ListFilter />} sort={{ field: 'title', order: 'ASC' }}>

                <Datagrid >
                    <ProfileidFiled source="profile_id" label="Profile Id" />
                    <TextField source='keyword_count' label="Count" />
                    <TextField source="submit_date" label="Submit Date" />
                    <TextField source="title" label="Title" />
                    <TextField source="amount" label="Amount" />
                    <FunctionField source="status" label="Status" render={record => {
                        if (record['status'] === 'Reject') {
                            return (<span style={{ color: 'red' }}>Reject</span>)
                        } else if (record['status'] === 'Pending') {
                            return (<span style={{ color: 'orange' }} >Pending</span>)
                        }
                        else {
                            return (<span style={{ color: 'green' }}>Approved</span>)
                        }
                    }} />
               
                   
                    {useCheckPermissions('ContentWriter_Edit') ? <OpenDialogButton onClick={handleOpenDialog} /> : null}
                    <ContentUrl />
                    <PreviewImgeUploaded />
                    <TextField source="reason" label="Reason" />
                    <TextField source="action_at" label="Action At" />
                    <TextField source="actionBy.name" label="Action By" />
                    <ExportHtml />
                    {useCheckPermissions('ContentWriter_View') ? <ShowButton /> : null}
                </Datagrid>
                {/* Dialog Component */}
                <Dialog maxWidth='900px' open={open} onClose={handleCloseDialog}>
                    <DialogTitle> Record Id - {selectedId} {recordStatus}</DialogTitle>

                    <SimpleForm sx={{ minWidth: '400px', }} onSubmit={(record) => handleSubmit(record)} toolbar={<FormToolbar onCancel={() => { handleCloseDialog() }} />} >
                        <SanitizedGrid container spacing={3} fullWidth>
                            <Grid item sm={12} lg={12}>
                                <SelectInput validate={required()} source="status" label="Status" fullWidth choices={choices}{...props} />


                            </Grid>
                            <Grid item sm={12} lg={12}>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) =>
                                        formData.status === "Reject" && (
                                            <TextInput source="decline_reason" label="Reason" fullWidth resettable multiline validate={required()} />
                                        )
                                    }
                                </FormDataConsumer>

                            </Grid>
                        </SanitizedGrid>
                    </SimpleForm>


                </Dialog>

            </List>
        </Fragment>
    )
}


export default ContentWriterList