import { fetchPost } from '../Service/ApiService'

export const actionOfMicroAndContentWritter = (data,recordId,resolve, reject) => {
   

    try {
        fetchPost('contentwriterandmicrodeduction/'+recordId+'/'+data.type, data, {
            success: (res) => { resolve(res) },
            fail: (res) => { reject(res) },
            error: (err) => {
                reject({ status: false, message: "Unable to connect server" })
            }
        })
    } catch (e) {
        reject({ status: false, message: "Unable to process operation" })
    }
};









